import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import DraggableBox from './DraggableBox';

const DropContainer = () => {
  const [droppedServices, setDroppedServices] = useState([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'SERVICE',
    drop: (item, monitor) => addServiceToContainer(item.service, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addServiceToContainer = (service, monitor) => {
    const clientOffset = monitor.getClientOffset(); // Get drop position
    const newService = {
      ...service,
      position: {
        x: clientOffset.x - 200, // Adjusting offset for correct positioning
        y: clientOffset.y - 50,
      },
    };
    setDroppedServices((prevServices) => [...prevServices, newService]);
  };

  return (
    <div
      ref={drop}
      style={{
        border: '2px solid black',
        padding: '20px',
        minHeight: '400px',
        position: 'relative',
      }}
    >
      {droppedServices.map((service, index) => (
        <DraggableBox key={index} service={service} />
      ))}
    </div>
  );
};

export default DropContainer;
