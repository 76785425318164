// AWSServiceList.js
import React from 'react';
import { useDrag } from 'react-dnd';

const AWSServiceList = ({ service }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'SERVICE',
    item: { service },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {service.name}
    </div>
  );
};

export default AWSServiceList;
