import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AWSServiceList from './AWSServiceList';
import DropContainer from './DropContainer';


const awsServices = [
  { name: 'EC2' },
  { name: 'S3' },
  { name: 'Lambda' },
  { name: 'RDS' },
  { name: 'DynamoDB' },
  { name: 'API' },
  { name: 'SQS' },
  { name: 'SNS' },
  // Add more services...
];

const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '200px', borderRight: '2px solid black' }}>
          {awsServices.map((service, index) => (
            <AWSServiceList key={index} service={service} />
          ))}
        </div>
        <div style={{ flex: 1, padding: '20px' }}>
          <DropContainer />
        </div>
      </div>
    </DndProvider>
  );
};

export default App;
