import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import Modal from 'react-modal';

const DraggableBox = ({ service }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [position, setPosition] = useState(service.position);
  const [dragging, setDragging] = useState(false);

  // Handle drag stop to update position
  const onDragStop = (e, d) => {
    setPosition({ x: d.x, y: d.y });
    setDragging(false); // Reset dragging state
  };

  // Handle when dragging starts
  const onDragStart = () => {
    setDragging(true); // Indicate that dragging is occurring
  };

  // Handle when box is clicked
  const handleClick = () => {
    // Only open the modal if the box wasn't dragged (dragging state is false)
    if (!dragging) {
      setModalIsOpen(true);
    }
  };

  return (
    <>
      <Rnd
        position={{ x: position.x, y: position.y }}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        bounds="parent"
        style={{
          padding: '10px',
          margin: '10px',
          border: '1px solid gray',
          backgroundColor: 'lightgray',
          cursor: 'move',
        }}
      >
        <div onClick={handleClick}>
          {service.name}
        </div>
      </Rnd>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <h2>Edit {service.name}</h2>
        <form>
          {/* Add form fields for the service */}
          <button type="button" onClick={() => setModalIsOpen(false)}>Close</button>
        </form>
      </Modal>
    </>
  );
};

export default DraggableBox;
